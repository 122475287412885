<template xmlns="http://www.w3.org/1999/html">
  <div>
    <div class="row">
      <div class="col-lg-12">

        <div class="card" v-if="car">
          <div class="card-body">
            <div class="row">
              <div class="col-lg-5">
                <div class="row justify-content-center">
                  <div class="col-xl-8">
                    <div id="product-carousel" class="carousel slide product-detail-carousel" data-ride="carousel">
                      <div class="carousel-inner">
                        <div class="carousel-item active">
                          <div v-if="car">
                            <img :src="`${car.mainImage ? car.mainImage.url : ''}`" alt="product-img"
                                 style="width: 300px; height: 300px; object-fit: contain;" id="myImage"
                                 class="img-fluid"/>
                          </div>
                        </div>

                        <div v-if="car">
                          <div class="carousel-item" v-for="image in car.supportingImages">
                            <div>
                              <img :src="`${image ? image.url : ''}`" alt="product-img"
                                   style="width: 250px; height: 200px; object-fit: contain;" class="img-fluid"/>
                            </div>
                          </div>
                        </div>

                      </div>
                      <ol class="carousel-indicators product-carousel-indicators mt-2" v-if="car">
                        <li data-target="#product-carousel" data-slide-to="0" class="active">
                          <img :src="`${car.mainImage ? car.mainImage.url: ''}`" @click="changeImage($event)"
                               alt="product-img" class="img-fluid product-nav-img"/>
                        </li>
                        <li data-target="#product-carousel" :data-slide-to="index+1"
                            v-for="(image, index) in car.supportingImages">
                          <img :src="`${image.url}`" @click="changeImage($event)" alt="product-img"
                               class="img-fluid product-nav-img"/>
                        </li>

                      </ol>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-7">
                <div>


                  <h4 class="mt-3 mb-2">Car Details</h4>
                  <div class="table-responsive">
                    <table class="table table-bordered table-centered mb-0">
                      <tbody>
                      <tr>
                        <td>Car name</td>
                        <td>
                          {{ car.name }}
                          <a href="javascript: void(0);" class="text-muted"
                             @click="$router.push('/rentals/edit-car/' + car.id)">
                            <i class="mdi mdi-square-edit-outline ml-2"></i>
                          </a>
                        </td>
                      </tr>
                      <tr v-for="(field,i) in additionalFields" :key="i">
                        <td>{{ field.fieldName }}</td>
                        <td>
                          {{ field.fieldValue }}
                        </td>
                      </tr>
                      <tr v-if="this.uniqueIdFieldName && this.uniqueIdFieldName.hasUniqueIdField === 'Yes'">
                        <td>Unique Identifier</td>
                        <td>
                          <span v-if="this.uniqueIdFieldName">
                           {{this.uniqueIdFieldName.hasUniqueIdField === 'Yes' ? this.uniqueIdFieldName.uniqueIdFieldName : 'N/A'}}
                          </span>
                          <span v-else>
                            N/A
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Rental Status
                        </td>
                        <td>
                          <div>
                            <span v-if="!car.current_assigned_to" class="badge badge-success p-1">Available</span>
                            <div v-else>
                              <span
                                  class="badge badge-success p-1">In use by {{
                                  car.current_assigned_to['name']
                                }} {{ car.current_assigned_to['other_name'] }}</span>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td colspan="2" class="text-center">
                          <a v-if="!car.current_assigned_to" href="#" class="btn btn-dark mr-2"
                             @click.prevent="$router.push('/rentals/assign-car/' + car.id)"><i class="fa fa-car"></i>
                            Assign car to a driver</a>
                          <a v-else href="#" class="btn btn-danger mr-2"
                             @click.prevent="unassignCar(car.id)"><i class="fa fa-car"></i>
                            Un assign car</a>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="car.car_assignment">
              <h5 class="mt-3 mb-2">Rental Summary</h5>
              <div class="table-responsive">
                <table class="table table-bordered table-centered mb-0">
                  <thead>
                  <tr>
                    <th>Duration</th>
                    <th>Duration Length</th>
                    <th>PickUp Date</th>
                    <th>Return Date</th>
                    <th>Cost Per Period</th>
                    <th>Payment Option</th>
                    <!--                    <th>Send Automatic Invoice</th>-->
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td>{{ car.car_assignment.duration }}</td>
                    <td>{{ car.car_assignment.duration_length }}</td>
                    <td>{{ car.car_assignment.pickup_date }} {{ car.car_assignment.pickup_time }}</td>
                    <td>{{ car.car_assignment.return_date }} {{ car.car_assignment.return_time }}</td>
                    <td>{{ car.car_assignment.cost_per_period | toCurrencyFormat }}</td>
                    <td>{{ car.car_assignment.payment_option }}</td>
                    <!--                    <td>{{ car.car_assignment.send_invoice }}</td>-->
                  </tr>
                  </tbody>
                </table>
            </div>
            <div class="table-responsive">
              <h5 class="mt-3 mb-2">Rental Calculated Configurations for Driver</h5>
              <table class="table table-bordered table-centered mb-0">
                <thead>
                <tr>
                  <th>Field Name</th>
                  <th>Field Type</th>
                  <th>Gross / Net Name</th>
                  <th>Use In Weekly Payment</th>
                  <th>Use In F Administration</th>
                  <th>Field Value</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(field, i) in car.car_assignment['calculated_field']" :key="i">
                  <td>{{ field.fieldName }}</td>
                  <td>{{ field.fieldType }}</td>
                  <td>{{ field.grossOrNet }}</td>
                  <td>{{ field.weeklyPayment }}</td>
                  <td>{{ field.fAdministration }}</td>
                  <td>{{ field.fieldValue | toCurrencyFormat }}</td>
                </tr>
                </tbody>
              </table>

              <h5 class="mt-3 mb-2">Rental Tax Configuration for Driver</h5>
              <div class="table-responsive">
                <table class="table table-bordered table-centered mb-0">
                  <thead>
                  <tr>
                    <th>Reference Field Name</th>
                    <th>Reference Field Type</th>
                    <th>Tax Type</th>
                    <th>Formula</th>
                    <th>Use In Weekly Payment</th>
                    <th>Use In F Administration</th>
                    <th>Value</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(field, i) in car.car_assignment['tax_field']" :key="i">
                    <td>{{ field.refFieldName }}</td>
                    <td>{{ field.refFieldType }}</td>
                    <td>{{ field.taxType }}</td>
                    <td>{{ field.formula }}</td>
                    <td>{{ field.weeklyPayment }}</td>
                    <td>{{ field.fAdministration }}</td>
                    <td>{{ field.value | toCurrencyFormat }}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
            </div>
            <div v-else>
              <h5 class="mt-3 mb-2">Default Calculated Configuration</h5>
              <div class="table-responsive">
                <table class="table table-bordered table-centered mb-0" v-if="calculatedFields.length > 0">
                  <thead>
                  <tr>
                    <th>Field Name</th>
                    <th>Field Value</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(field, i) in calculatedFields" :key="i">
                    <td>{{ field.fieldName }}</td>
                    <td>{{ field.fieldValue }}</td>
                  </tr>
                  </tbody>
                </table>
                <h6 v-else>No calculated configurations</h6>
              </div>

              <h5 class="mt-3 mb-2">Default Tax Configuration</h5>
              <div class="table-responsive">
                <table class="table table-bordered table-centered mb-0" v-if="taxFields.length > 0">
                  <thead>
                  <tr>
                    <th>Reference Field Name</th>
                    <th>Reference Field Type</th>
                    <th>Tax Type</th>
                    <th>Formula</th>
                    <th>Use In Weekly Payment</th>
                    <th>Use In F Administration</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(field, i) in taxFields" :key="i">
                    <td>{{ field.refFieldName }}</td>
                    <td>{{ field.refFieldType }}</td>
                    <td>{{ field.taxType }}</td>
                    <td>{{ field.formula }}</td>
                    <td>{{ field.weeklyPayment }}</td>
                    <td>{{ field.fAdministration }}</td>
                  </tr>
                  </tbody>
                </table>
                <h6 v-else>No tax configurations</h6>
              </div>
            </div>
            <div class="row" v-if="!car.car_assignment">
              <div class="col-12 text-center">
                <button class="btn btn-info" @click="$router.push('/rentals/edit-car/' + car.id)">Edit Car
                  Configuration
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- end card -->
      </div>
    </div>

  </div>
</template>

<script>

import vue2Dropzone from "vue2-dropzone";
import {confirm} from "@/utils/functions";
import {rentalsService} from "@/apis/rentals.service";


export default {
  name: 'CarDetail',
  head() {
    return {
      title: `${this.title} | RedCabs`,
    };
  },
  components: {
    vueDropzone: vue2Dropzone,
  },
  data() {
    return {
      dropzoneOptions: {
        url: '#',
        maxFilesize: 0.5,
        acceptedFiles: ".jpeg,.jpg,.png,.gif",
        addRemoveLinks: false,
        autoProcessQueue: false,
        maxFiles: 20,
      },
      numberOfFuelUnitsUsed: 0,
      hasReducedRiskOfDamage: false,
      saved: false,
      calculatedWithTaxFields: [],
      stateOfCarImages: [],
      departureControlImages: [],
      arrivalControlImages: [],
      costFields: [
        {
          fieldName: '',
          fieldValue: '',
          fieldType: 'cost', // income / cost
          weeklyPayment: true,
          fAdministration: true,
        }
      ],
      departureForm: {
        kmBeforeDeparture: ''
      },
      arrivalForm: {
        kmOnArrival: '',
        returnDateTime: '',
        extraDuration: '',
        grossRiskOfDamage: '',
        initialRiskOfDamagePaid: '',
        recurringRiskOfDamage: '',
        remainingRiskOfDamage: '',
        arrivalRiskOfDamage: '',
        remainingRentalCost: '',
        baseRentalCost: '',
        totalDurationInDays: ''

      },
      rental: null,
      confirmArrivalControl: false,
      payload: null,
      carId: this.$route.params.id,
      car_config: null,
      uniqueIdFieldName: {
        hasUniqueIdField: 'No',
        uniqueIdFieldName: ''
      },
      totalField: {
        hasTotalField: 'Yes',
        totalField: 'Yes'
      },
      dateTimeField: {
        hasDateTimeField: 'No',
        dateTimeField: ''
      },
      calculatedFields: [],
      taxFields: [],
      additionalFields: [],
      car: null,
    }
  },
  computed: {
    costOfFuelUsed() {
      return this.numberOfFuelUnitsUsed * (this.car['price_per_unit_of_fuel'] || 0.00)
    }

  },
  middleware: "router-auth",
  methods: {
    /**
     * Change the product
     */
    changeImage(data) {
      document.getElementById("myImage").src = data.target.src
    },

    displayDepartureControlStatus(value) {
      if (!value)
        return "Not completed";

      if (value === "completed")
        return "Driver has received car keys";

      return value;

    },

    displayArrivalControlStatus(value) {
      if (!value)
        return "Not completed";

      if (value === "completed")
        return "Driver has returned car keys";

      return value;

    },
    handCompleteDepartureControl() {
      const payload = {
        'km_before_departure': this.departureForm.kmBeforeDeparture,
        'id': this.rental.id,
      }
      this.$store.dispatch('completeDepartureControl', payload)
    },
    handCompleteArrivalControl() {
      let mess = '';
      if (this.confirmArrivalControl) {
        mess = 'confirm arrival control completion'
      } else {
        mess = 'save arrival control'
      }
      confirm(mess, () => {
        const payload = {
          'km_on_arrival': this.arrivalForm.kmOnArrival,
          'actual_return_date': this.arrivalForm.returnDateTime,
          'id': this.rental.id,
          'fuel_used': this.numberOfFuelUnitsUsed,
          'confirm': this.confirmArrivalControl,
          'total_risk_of_damage': this.arrivalForm.arrivalRiskOfDamage,
          'calculatedWithTaxFields': this.calculatedWithTaxFields
        }

        this.$store.dispatch('completeArrivalControl', payload).then(() => {
          if (this.confirmArrivalControl) {
            location.reload();
          }
          this.$forceUpdate();
        })
      })
    },

    getCarDetail() {
      this.$store.dispatch('getCarDetail', this.carId).then((response) => {
        this.car = response;
        this.car_config = this.car.car_config;
        if (!this.car_config) {
          this.totalField = ''
          this.calculatedFields = [];
          this.taxFields = [];
          this.additionalFields = [];
          return;
        }

        if (this.car.car_assignment) {
          this.car.car_assignment['calculated_field'] = JSON.parse(this.car.car_assignment['calculated_field']);
          this.car.car_assignment['tax_field'] = JSON.parse(this.car.car_assignment['tax_field']);
        }

        this.calculatedFields = JSON.parse(this.car_config.calculated_fields)
        this.taxFields = JSON.parse(this.car_config['tax_fields'])
        this.additionalFields = JSON.parse(this.car_config['additional_fields']);

        if (this.car_config['total_field']) {
          this.totalField = JSON.parse(this.car_config['total_field']);
        }

        if (this.car_config['date_time_field']) {
          this.dateTimeField = JSON.parse(this.car_config['date_time_field']);
        }

        if (this.car_config['unique_identifier']) {
          this.uniqueIdFieldName = JSON.parse(this.car_config['unique_identifier']);
        }
      });
    },

    unassignCar(car_id) {
      confirm(`Are you sure you want to unassign driver. This will cancel their upcoming payments`, async () => {

        const payload = {
          car_id: car_id
        }
        this.$store.dispatch('unassignCar', payload).then(() => {
          this.getCarDetail();
        });
      });
    }
  },
  async created() {
    this.getCarDetail();
  }
};
</script>